import React, { useState } from "react";
import { PAGES } from "@app/constants";
import { Button, Modal } from "@app/components";
import { EVENTS, trackEvent } from "@app/utils/trackEvent";
import {
  Apple,
  GodotLogoFull,
  Phone,
  Play,
  Smile
} from "@app/components/Icons";

import { Typing } from "./Typing";
import { QuickstartForm } from "./QuickstartForm";
import { MetaData } from "../_Shared";

const Home = () => {
  const [showEmailModal, setShowEmailModal] = useState(false);

  return (
    <main>
      <MetaData page={PAGES.Home} />
      <section className="hero">
        <div className="hero-left">
          <div className="_mb10">
            <Play />
            <Phone />
            <Smile />
          </div>
          <h1 className="hero-h1">App Store ready Godot games in minutes.</h1>
          <p className="hero-p">
            Focus on building great games while we take care of getting them
            shipped to the <b style={{ color: "white" }}>App Store</b> and{" "}
            <b style={{ color: "white" }}>Google Play</b>.{" "}
          </p>
          <div className="hero-buttons">
            <Button
              size="md"
              className="_mt10"
              variant="secondary"
              href="/docs/guides/quick-start"
            >
              Quick start
            </Button>
            <Button
              size="md"
              className="_mt10 hero-quickStartEmail"
              variant="secondary"
              onClick={() => {
                setShowEmailModal(true);
                trackEvent(EVENTS.QUICKSTART_MODAL_OPEN);
              }}
            >
              Email me the Quick start guide.
            </Button>

            <Modal
              isShowing={showEmailModal}
              onClose={() => setShowEmailModal(false)}
            >
              <QuickstartForm />
            </Modal>

            <Button
              size="md"
              className="_mt10"
              variant="primary"
              href="https://npmjs.com/package/shipthis"
            >
              Get ShipThis
            </Button>
          </div>
        </div>
        <div className="hero-right">
          <Typing />
        </div>
      </section>

      <section className="intro">
        <div className="intro-pre">Features</div>
        <h3 className="intro-h3">
          Cloud-build your Godot games -<br />
          No Xcode or Android Studio needed
        </h3>
        <div className="intro-p">
          Once configured, ShipThis can rebuild new versions of your game with a
          single command. ShipThis will handle the entire build process,
          including code signing, and uploading to the App Store and Google
          Play.
        </div>
      </section>

      <section className="features grid grid--2col">
        <div className="featureList-cloud2" />
        <div className="featureList-wrap">
          <h2 className="features-headline">ShipThis Features</h2>
          <ul className="featureList">
            <li>Guided Setup</li>
            <li>Build with one command</li>
            <li>Credentials are managed for you</li>
            <li>Monitor builds in the dashboard</li>
            <li>View error logs and build history</li>
            <li>Integrates with existing CI</li>
          </ul>
          <div className="_flex _flex-center _mt10">
            <Apple fill="white" className="_mr10" width={43} height={43} />
            <GodotLogoFull height={55} width={131} />
          </div>
        </div>
      </section>
    </main>
  );
};

export { Home };
